import { Component, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NftService } from 'src/app/services/nft.service';
import { environment } from 'src/environments/environment';
import { opportunitiesData } from './opportunities.data';
import { risksData } from './risks.data';

@Component({
  selector: 'app-page-detalhes',
  templateUrl: './page-detalhes.component.html',
  styleUrls: ['./page-detalhes.component.scss']
})
export class PageDetalhesComponent {
  // changeNumber(event: any) {

  //   console.log(event);
  //   if (event.target.value < 0){
  //     this.inputValue = 0
  //   }
  // }

  ipfsHashFile: string = '';
  tokenId: string = '';
  file!: string;

  tokenDetalhe: any;
  childTokens: any;
  cestaList: any;
  precoTokenCarrinho: number = 1;
  quantidade: number = 1;

  showButton: boolean = true;

  opportunities = opportunitiesData;
  risks = risksData;
  documents: any;

  percentage: number;

  inputValue: number;
  result: number | null = null;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly nftService: NftService
  ) {
    this.tokenId = this.route.snapshot.params['hash'];

    // this.ipfsHashFile = this.route.snapshot.params['hash'];
    // this.tokenId = this.route.snapshot.queryParamMap.get('token');
  }

  ngOnInit(): void {
    this.getTokenDetailById(this.tokenId);
    this.getTokensFilhos(this.tokenId);
    // this.file = this.getLinkOrigem(this.ipfsHashFile);
  }

  // getLinkOrigem(ipfsHashFile: string): string {
  //   return environment.gatewayIpfs + ipfsHashFile;
  // }

  protected getTokenDetailById(id: string): void {
    this.nftService.getCarteiraByToken(id).subscribe({
      next: (response: any) => {
        if (response && response.body) {
          this.tokenDetalhe = response.body[0];
          this.calculatePercentage();
          this.opportunities.mains[0].description = this.tokenDetalhe.DsSumarioInvestimento;

          const docs = this.tokenDetalhe.JsonDocumentosAnexos;
          this.documents = this.parseDocumentos(docs);

          this.precoTokenCarrinho = this.tokenDetalhe.PrecoUn * this.quantidade;
        }
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  get allDocumentsWithoutUrl(): boolean {
    return this.documents?.length > 0 && this.documents?.every(detail => !detail.url);
  }

  private parseDocumentos(jsonResponse: string): any[] {
    const parsedDocumentos = JSON.parse(jsonResponse);
    return Object.values(parsedDocumentos);
  }

  protected getTokensFilhos(id: string): void {
    this.nftService.getNftTokensFilhos(id).subscribe({
      next: (response: any) => {
        if (response && response.body) {
          this.childTokens = response.body;
        }
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  protected realizarPedido(): void {
    var payload = {
      token: this.tokenId,
      qtd: Number(this.quantidade),
    };

    this.nftService.finalizarCompraNft(payload).subscribe({
      next: (response: any) => {
        if (response && response.body) {
          const idPedido = response.body['id_pedido'];
          setTimeout(() => {
            this.router.navigate(['/client/token-detalhe-pedido/' + idPedido]);
          });
        }
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  protected atualizarPrecos(event: any): void {
    this.quantidade = event.quantidadeTokens;
    this.precoTokenCarrinho = event.totalInvestido;
  }

  goTo(): void {
    this.router.navigate(['/produtos']);
  }

  openFile(file: string): void {
    window.open(file, '_blank');
  }

  scrollToSection(elementId: string): void {
    const section = document.getElementById(elementId);

    if (section) {
      const sectionPosition = section.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: sectionPosition - 50,
        behavior: 'smooth',
      });
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const section = document.getElementById('card-investment-token');

    if (section) {
      const sectionPosition = section.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      this.showButton = sectionPosition > windowHeight || sectionPosition < 0;
    }
  }

  onInputChange(event:any): void {
    this.result = null;

    if (event.target.value < 1){
      this.inputValue = 1;
      event.target.value = 1;

    }
    // this.result = (this.tokenDetalhe?.PrecoUn * this.inputValue) * this.tokenDetalhe?.VlMultiploBase;
    setTimeout(() => {
      if (event.target.value < 1){
        this.inputValue = 1;
        event.target.value = 1;
      }
      this.result = (this.tokenDetalhe?.PrecoUn * event.target.value) * this.tokenDetalhe?.VlMultiploBase;
    }, 500);
  }

  protected calculatePercentage(): void {
    this.percentage = (
      this.calculateAmountSold(
        this.tokenDetalhe.PrecoUn,
        this.tokenDetalhe.quantidade_criada_nft,
        this.tokenDetalhe.quantidade_disponivel) /
      this.calculateTotalAmount(
        this.tokenDetalhe.quantidade_criada_nft,
        this.tokenDetalhe.PrecoUn)
    ) * 100
  }

  protected calculateAmountSold(tokenPrice: number, tokenAmountTotal: number, tokenAmountAvailable: number): number {
    return (tokenAmountTotal - tokenAmountAvailable) * tokenPrice;
  }

  protected calculateTotalAmount(tokenAmountTotal: number, tokenPrice: number): number {
    return tokenAmountTotal * tokenPrice;
  }

}
