import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class ConfiguracaoService {
  //
  constructor(private http: HttpClient, private utilService: UtilService) {}

  checkService(service: string) {
    var observable = this.http
      .get(service + 'check/', { observe: 'response' })
      .pipe(map((Response) => Response));

    return observable;
  }

  getFaleConoscoById(id: any) {
    var observable = this.http
      .get(API_URL + 'sistema/fale_conosco/' + id, { observe: 'response' })
      .pipe(map((Response) => Response));

    return observable;
  }

  getAposentadoriaById(id: any) {
    var observable = this.http
      .get(API_URL + 'marketplace_nft/pedido_aposentadoria/' + id, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  putFaleConosco(id: any, obj: any) {
    var observable = this.http
      .put(API_URL + 'sistema/fale_conosco/' + id, obj, { observe: 'response' })
      .pipe(map((Response) => Response));

    return observable;
  }
  putAposentadoria(id: any, obj: any) {
    var observable = this.http
      .put(API_URL + 'marketplace_nft/pedido_aposentadoria/', obj, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  getUnidadesNegocio() {
    //todas as unidades
    var observable = this.http
      .get(API_URL + 'sistema/unidade_negocio/', { observe: 'response' })
      .pipe(map((Response) => Response));

    return observable;
  }

  entreEmContatoCorporativo(obj: object) {
    var observable = this.http
      .post(API_URL + 'sistema/fale_conosco/', obj, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  getLogs(filter: any, sort: any, limit: any, exportData: boolean = false) {
    var strFilter = this.utilService.convertObjectFilterToStringFilter(
      filter,
      sort,
      limit,
      null,
      exportData
    );
    var observable = this.http
      .get(API_URL + 'sistema/log?' + strFilter, { observe: 'response' })
      .pipe(map((Response) => Response));

    return observable;
  }

  //Usuario

  getUsuario(id: string) {
    var observable = this.http
      .get(API_URL + 'sistema/user/' + id, { observe: 'response' })
      .pipe(map((Response) => Response));

    return observable;
  }

  getUsuarios(filter: any, sort: any, limit: any, exportData: boolean = false) {
    var strFilter = this.utilService.convertObjectFilterToStringFilter(
      filter,
      filter.sort,
      filter.limit,
      null,
      exportData
    );
    var observable = this.http
      .get(API_URL + 'sistema/user?' + strFilter, { observe: 'response' })
      .pipe(map((Response) => Response));

    return observable;
  }

  putUsuario(oUsuario: any) {
    //@ts-ignore
    var observable = this.http
      .put(
        API_URL + 'sistema/user/' + oUsuario.id,
        oUsuario,
        { observe: 'response' }
      )
      .pipe(map((Response) => Response));

    return observable;
  }

  postUsuario(oUsuario: object) {
    var observable = this.http
      .post(API_URL + 'sistema/user/', oUsuario, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  postLogin(oLogin: any) {
    var observable = this.http
      .post(API_URL + 'sistema/user/', oLogin, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }


  deleteUsuario(id: string) {
    var observable = this.http
      .delete(API_URL + 'sistema/user/', {
        observe: 'response',
      })
      .pipe(map((Response) => Response));
    return observable;
  }

 
  getTelaPorUser(oUser: string) {
    var observable = this.http
      .get(API_URL + 'sistema/telas-user/' + oUser, { observe: 'response' })
      .pipe(map((Response) => Response));

    return observable;
  }

  //Skill

  getSkills(filter: any, sort: any, limit: any, exportData: boolean = false) {
    var strFilter = this.utilService.convertObjectFilterToStringFilter(
      filter,
      sort,
      limit,
      null,
      exportData
    );
    var observable = this.http
      .get(API_URL + 'sistema/skill?' + strFilter, { observe: 'response' })
      .pipe(map((Response) => Response));

    return observable;
  }

  getSkill(id: string) {
    var observable = this.http
      .get(API_URL + 'sistema/skill/' + id, { observe: 'response' })
      .pipe(map((Response) => Response));

    return observable;
  }

  getSkillTelaNaoCadastradasDoSkill(skill_id: string, exportData = false) {
    var observable = this.http
      .get(
        API_URL + 'sistema/skill_tela/skill_tela_nao_cadastradas_do_skill/' + skill_id,
        { observe: 'response' }
      )
      .pipe(map((Response) => Response));

    return observable;
  }

  getSkillTelaCadastradasDoSkill(skill_id: string, exportData = false) {
    var observable = this.http
      .get(API_URL + 'sistema/skill_tela/skill_tela_por_skill_id/' + skill_id, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  getSkillTelas(
    filter: any,
    sort: any,
    limit: any,
    exportData: boolean = false
  ) {
    var strFilter = this.utilService.convertObjectFilterToStringFilter(
      filter,
      sort,
      limit,
      null,
      exportData
    );

    var observable = this.http
      .get(API_URL + 'sistema/skill_tela?' + strFilter, { observe: 'response' })
      .pipe(map((Response) => Response));

    return observable;
  }

  getSkillTela(id: string) {
    var observable = this.http
      .get(API_URL + 'sistema/skill_tela/' + id, { observe: 'response' })
      .pipe(map((Response) => Response));

    return observable;
  }

  putSkill(oSkill: any) {
    //@ts-ignore
    var observable = this.http
      .put(API_URL + 'sistema/skill/' + oSkill.id, oSkill, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  postSkill(oSkill: object) {
    var observable = this.http
      .post(API_URL + 'sistema/skill/', oSkill, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  deleteSkill(id: string) {
    var observable = this.http
      .delete(API_URL + 'sistema/skill/' + id, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));
    return observable;
  }

  //Parametros

  getParametros(
    filter: any,
    sort: any,
    limit: any,
    exportData: boolean = false
  ) {
    var strFilter = this.utilService.convertObjectFilterToStringFilter(
      filter,
      sort,
      limit,
      null,
      exportData
    );
    var observable = this.http
      .get(API_URL + 'sistema/parameters?' + strFilter, { observe: 'response' })
      .pipe(map((Response) => Response));

    return observable;
  }

  getParametro(id: string) {
    var observable = this.http
      .get(API_URL + 'sistema/parameters/' + id, { observe: 'response' })
      .pipe(map((Response) => Response));

    return observable;
  }

  getParametroNome(name: string) {
    var observable = this.http
      .get(API_URL + 'sistema/parameters/value_by_name/'+name, { observe: 'response' })
      .pipe(map((Response) => Response));

    return observable;
  }
  
  putParametro(oParametro: any) {
    //@ts-ignore
    var observable = this.http
      .put(
        API_URL + 'sistema/parameters/' + oParametro.id,
        oParametro,
        { observe: 'response' }
      )
      .pipe(map((Response) => Response));

    return observable;
  }

  postParametro(oParametro: any) {
    var observable = this.http
      .post(API_URL + 'sistema/parameters/', oParametro, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  deleteParametro(id: string) {
    var observable = this.http
      .delete(API_URL + 'sistema/parameters/' + id, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));
    return observable;
  }

  // Tela

  getTelas(
    filter: any,
    sort: any,
    limit: any,
    _like_cols: any,
    exportData: boolean = false
  ) {
    var strFilter = this.utilService.convertObjectFilterToStringFilter(
      filter,
      sort,
      limit,
      _like_cols,
      exportData
    );
    var observable = this.http
      .get(API_URL + 'sistema/tela?' + strFilter, { observe: 'response' })
      .pipe(map((Response) => Response));

    return observable;
  }

  getTela(id: string) {
    var observable = this.http
      .get(API_URL + 'sistema/tela/' + id, { observe: 'response' })
      .pipe(map((Response) => Response));

    return observable;
  }

  postTela(oTela: any) {
    var observable = this.http
      .post(API_URL + 'sistema/tela/', oTela, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  postSkillTela(oSkillTela: any) {
    var observable = this.http
      .post(API_URL + 'sistema/skill_tela/', oSkillTela, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  deleteSkillTela(id: any) {
    var observable = this.http
      .delete(API_URL + 'sistema/skill_tela/' + id, { observe: 'response' })
      .pipe(map((Response) => Response));

    return observable;
  }
  putTela(oTela: any) {
    var observable = this.http
      .put(API_URL + 'sistema/tela/' + oTela.id, oTela, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  deleteTela(id: string) {
    var observable = this.http
      .delete(API_URL + 'sistema/tela/' + id, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));
    return observable;
  }

  deleteTelaByRouterLink(router_link: string) {
    var observable = this.http
      .delete(
        API_URL + 'sistema/tela/by_router_link/' + router_link,
        { observe: 'response' }
      )
      .pipe(map((Response) => Response));
    return observable;
  }

  getPagamentos() {
    var observable = this.http
      .get(API_URL + 'sistema/parameters/', { observe: 'response' })
      .pipe(map((Response) => Response));

    return observable;
  }

  postEnviaDocumentos(id: string, documentos: any) {
    var observable = this.http
      .post(API_URL + 'sistema/user/documentos/' + id, documentos, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }
  postEnviaFoto(id: string, foto: any) {
    var observable = this.http
      .post(API_URL + 'sistema/user/documentos/' + id, foto, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }
}
