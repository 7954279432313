// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//local"http://localhost:5000/aidda_api/v1/" homologação""
//local"http://localhost:3000/" mock""
//apiLoginUrl: 'http://fakservice:4000'

// gatewayIpfs de verdade
// https://gateway.pinata.cloud/ipfs/
// https://ipfs.io/ipfs/

// servico com a imagem em miniatura
// 'http://localhost:5000/aidda_api/v1/get_mini_image_ipfs/'

// fake local
// http://localhost:5000/aidda_api/v1/get_mini_image_ipfs/

// testenet
// gatewayHathor:'https://explorer.testnet.hathor.network/token_detail/'




export const environment = {//http://localhost
  production: false,  
  apiUrl: "http://localhost:5000/aidda_api/v1/",
  apiLoginUrl: 'http://localhost:5000/aidda_api/v1/',
  gatewayMiniatura: 'http://localhost:5000/aidda_api/v1/get_mini_image_ipfs/',
  gatewayIpfs: 'https://ipfs.io/ipfs/',
  gatewayHathor:'https://explorer.testnet.hathor.network/token_detail/',
  apiNft: 'http://localhost:5000/aidda_api/v1/',
  grafanaUrl: 'http://localhost:3000/d/9SBr4Ju7z/teste-123?from=1652952469733&to=1652974069733&orgId=1',
};

  

// export const environment = {//http://localhost 
//   production: false,  
//   apiUrl: "https://backend-mvativos-dev-480366922823.us-central1.run.app/aidda_api/v1/",
//   apiLoginUrl: 'https://backend-mvativos-dev-480366922823.us-central1.run.app/aidda_api/v1/',
//   gatewayMiniatura: 'https://backend-mvativos-dev-480366922823.us-central1.run.app/aidda_api/v1/get_mini_image_ipfs/',
//   gatewayIpfs: 'https://backend-mvativos-dev-480366922823.us-central1.run.app/aidda_api/v1/get_mini_image_ipfs/',
//   apiNft: 'https://backend-mvativos-dev-480366922823.us-central1.run.app/aidda_api/v1/',
//   grafanaUrl: 'https://backend-mvativos-dev-480366922823.us-central1.run.app:3000/d/9SBr4Ju7z/teste-123?from=1652952469733&to=1652974069733&orgId=1',
//   gatewayHathor:'https://explorer.testnet.hathor.network/token_detail/'
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
