<div class="mat-mdc-dialog">
  <div class="dialog-header-actions">
    <div class="dialog-close" [mat-dialog-close]="true">
      <mat-icon>close</mat-icon>
    </div>
  </div>
  <ng-container>
    <div class="dialog-header-content">
      <img
        src="assets/images/pix.png"
        alt="Logotipo do pagamento PIX"
        class="dialog-icon"
        width="99"
      />
      <h2 class="dialog-title">Pagamento Pix</h2>
      <p class="dialog-subtitle">
        Abra o app em que vai fazer a transferência, escaneie a imagem ou cole o
        código do QR Code:
      </p>
    </div>
    <form>
      <div class="row text-center">
        <div class="col">
          <qrcode class="img-qrcode" [qrdata]="data?.linkPagamento" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>

          <ng-container>
            <p class="payment-value">{{ data?.valorPagamento | currency }}</p>
            <!-- <p class="payment-expiration">Válido até 10/10/2023: 10:00</p> -->
          </ng-container>
        </div>
      </div>
      <div class="dialog-actions dialog-actions--centered">
        <button
          class="btn-large text-uppercase mt-25"
          type="button"
          (click)="copy()"
          mat-flat-button
          color="primary"
        >
          Copiar QR Code
          <mat-icon>content_copy</mat-icon>
        </button>
        <button 
        class="btn-large text-uppercase mt-25"
        type="button"
        (click)="paymentMade()"
        mat-flat-button
        color="primary"
        >
          Pagamento Efetuado
        </button>
      </div>
    </form>
  </ng-container>
</div>
