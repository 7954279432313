<div class="page">
    <section class="py-5 bg-white">
        <div class="container">
            <div class="row g-5">
                <div class="col-lg-6">
                    <h1 class="text-main mb-4 d-block d-lg-none" translate="no">{{ tokenDetalhe?.description_nft }}</h1>

                    <img class="rounded w-100" src="{{ tokenDetalhe?.UrlImagemCarteira }}" alt="">
                    <div class="row mt-4">
                        <div class="col-6">
                            <button mat-stroked-button class="mat-button-base mat-primary w-100" color="main"
                                (click)="openFile(tokenDetalhe.UrlPdfCarteira)">Lâmina
                                técnica</button>
                        </div>
                        <div class="col-6">
                            <button mat-raised-button class="mat-raised-button mat-button-base mat-primary w-100"
                                (click)="scrollToSection('section-pagamento')" color="main">Investir</button>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <h1 class="text-main mb-5 d-none d-lg-block" translate="no">{{ tokenDetalhe?.description_nft }}</h1>

                    <div class="my-4">
                        <div class="d-flex justify-content-between mb-2">
                            <span translate="no">Captados {{ percentage | number:'1.1-2':'pt' }}%</span>
                            <span *ngIf="tokenDetalhe?.falta_n_dias" translate="no">Faltam {{ tokenDetalhe?.falta_n_dias }} dias</span>
                        </div>
                        <mat-progress-bar class="default-progress-bar" mode="determinate" [value]="percentage"
                            color="primary"></mat-progress-bar>
                    </div>

                    <div class="row g-3">
                        <div class="col-6">
                            <div class="card card-info p-3">
                                <h6 class="mb-0">Rentabilidade anual</h6>
                                <p class="mb-0 text-main" translate="no">{{ tokenDetalhe?.VlPorcentagemRentabilidadeTIRAoAnoBase |
                                    number:'1.1-2':'pt' }}% a.a.</p>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="card card-info p-3">
                                <h6 class="mb-0">Prazo estimado</h6>
                                <p class="mb-0 text-main" translate="no">{{ tokenDetalhe?.VlPrazoMedioRecebimentoBase }} meses</p>
                            </div>
                        </div>
                    </div>
                    <div class="row g-3 mt-1">
                        <div class="col-6">
                            <div class="card card-info p-3">
                                <h6 class="mb-0">Tipo de risco</h6>
                                <p class="mb-0 text-main" translate="no">{{ tokenDetalhe?.DsTipoRisco }}</p>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="card card-info p-3">
                                <h6 class="mb-0">Aporte mínimo</h6>
                                <p class="mb-0 text-main" translate="no">{{ tokenDetalhe?.PrecoUn || 0 | currency:'BRL' }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="row g-3 mt-1">
                        <div class="col-12">
                            <div class="card card-info p-3">
                                <h6 class="mb-0">Originadora</h6>
                                <p class="mb-0 text-main" translate="no">{{ tokenDetalhe?.DsOriginadora }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="row g-3 mt-1">
                        <div class="col-6 col-md-5">
                            <div class="card card-info p-3">
                                <h6 class="mb-0">Data de início</h6>
                                <p class="mb-0 text-main" translate="no">{{ tokenDetalhe?.DtInicioCaptacao | date:'dd/MM/yyyy \'às\'
                                    HH:mm' }}</p>
                            </div>
                        </div>
                        <div class="col-6 col-md-5">
                            <div class="card card-info p-3">
                                <h6 class="mb-0">Fim da captação</h6>
                                <p class="mb-0 text-main" translate="no">{{ tokenDetalhe?.DtFimCaptacao | date:'dd/MM/yyyy \'às\'
                                    HH:mm' }}</p>
                            </div>
                        </div>

                        <div class="col-6 col-md-2">
                            <div class="card card-info p-3">
                                <h6 class="mb-0">Moeda</h6>
                                <p class="mb-0 text-main" >BRL</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="py-2 bg-white">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-4 mb-3">
                    <button mat-stroked-button class="mat-button-base mat-primary w-100" color="main"
                        (click)="scrollToSection('cenarios')">Cenários</button>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-3">
                    <button mat-stroked-button class="mat-button-base mat-primary w-100" color="main"
                        (click)="scrollToSection('documentos')">Documentos</button>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-3">
                    <button mat-stroked-button class="mat-button-base mat-primary w-100" color="main"
                        (click)="scrollToSection('originadora')">Sobre a originadora</button>
                </div>
            </div>
        </div>
    </section>

    <section class="bg-alternative-light-opacity py-5" id="cenarios">
        <div class="container">
            <h2 class="text-main mb-5">Cenários projetados</h2>

            <mat-tab-group #tabGroup mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="500ms" [selectedIndex]="1">
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Otimista</span>
                    </ng-template>
                    <div class="tab-body" mat-tab-body>
                        <div class="mt-3">
                            <div class="d-flex justify-content-between p-3">
                                <div class="text-muted">Exposição máxima</div>
                                <div class="fw-bold">-</div>
                            </div>

                            <div class="d-flex justify-content-between border-top p-3">
                                <div class="text-muted">Rentabilidade</div>
                                <div class="fw-bold" translate="no">{{ tokenDetalhe?.VlPorcentagemRentabilidadeCDIOtimista |
                                    number:'1.1-2':'pt' }}%</div>
                            </div>

                            <div class="d-flex justify-content-between border-top p-3">
                                <div class="text-muted">Prazo estimado</div>
                                <div class="fw-bold" translate="no">{{ tokenDetalhe?.VlPrazoMedioRecebimentoOtimista }} meses</div>
                            </div>

                            <div class="d-flex justify-content-between border-top p-3">
                                <div class="text-muted">Rentabilidade a.a</div>
                                <div class="fw-bold" translate="no">{{ tokenDetalhe?.VlPorcentagemRentabilidadeTIRAoAnoOtimista |
                                    number:'1.1-2':'pt' }}% a.a.</div>
                            </div>

                            <div class="d-flex justify-content-between border-top p-3">
                                <div class="text-muted">Múltiplo</div>
                                <div class="fw-bold" translate="no">{{ tokenDetalhe?.VlMultiploOtimista | number:'1.2-2':'pt' }} x
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>

                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Base</span>
                    </ng-template>
                    <div class="tab-body" mat-tab-body>
                        <div class="mt-3">
                            <div class="d-flex justify-content-between p-3">
                                <div class="text-muted">Exposição máxima</div>
                                <div class="fw-bold">-</div>
                            </div>

                            <div class="d-flex justify-content-between border-top p-3">
                                <div class="text-muted">Rentabilidade</div>
                                <div class="fw-bold" translate="no">{{ tokenDetalhe?.VlPorcentagemRentabilidadeCDIBase |
                                    number:'1.1-2':'pt' }}%</div>
                            </div>

                            <div class="d-flex justify-content-between border-top p-3">
                                <div class="text-muted">Prazo estimado</div>
                                <div class="fw-bold" translate="no">{{ tokenDetalhe?.VlPrazoMedioRecebimentoBase }} meses</div>
                            </div>

                            <div class="d-flex justify-content-between border-top p-3">
                                <div class="text-muted">Rentabilidade a.a</div>
                                <div class="fw-bold" translate="no">{{ tokenDetalhe?.VlPorcentagemRentabilidadeTIRAoAnoBase |
                                    number:'1.1-2':'pt' }}% a.a.</div>
                            </div>

                            <div class="d-flex justify-content-between border-top p-3">
                                <div class="text-muted">Múltiplo</div>
                                <div class="fw-bold" translate="no">{{ tokenDetalhe?.VlMultiploBase | number:'1.2-2':'pt' }} x</div>
                            </div>
                        </div>
                    </div>
                </mat-tab>

                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Pessimista</span>
                    </ng-template>
                    <div class="tab-body" mat-tab-body>
                        <div class="mt-3">
                            <div class="d-flex justify-content-between p-3">
                                <div class="text-muted">Exposição máxima</div>
                                <div class="fw-bold">-</div>
                            </div>

                            <div class="d-flex justify-content-between border-top p-3">
                                <div class="text-muted">Rentabilidade</div>
                                <div class="fw-bold" translate="no">{{ tokenDetalhe?.VlPorcentagemRentabilidadeCDIPessimista |
                                    number:'1.1-2':'pt' }}%</div>
                            </div>

                            <div class="d-flex justify-content-between border-top p-3">
                                <div class="text-muted">Prazo estimado</div>
                                <div class="fw-bold" translate="no">{{ tokenDetalhe?.VlPrazoMedioRecebimentoPessimista }} meses</div>
                            </div>

                            <div class="d-flex justify-content-between border-top p-3">
                                <div class="text-muted">Rentabilidade a.a</div>
                                <div class="fw-bold" translate="no">{{ tokenDetalhe?.VlPorcentagemRentabilidadeTIRAoAnoPessimista }}%
                                    a.a.</div>
                            </div>

                            <div class="d-flex justify-content-between border-top p-3">
                                <div class="text-muted">Múltiplo</div>
                                <div class="fw-bold" translate="no">{{ tokenDetalhe?.VlMultiploPessimista | number:'1.2-2':'pt' }} x
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>

            </mat-tab-group>
        </div>
    </section>

    <section class="bg-alternative-dark-opacity text-white py-5">
        <div class="container px-lg-7">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <h2 class="text-main">Veja quanto seu dinheiro pode render com essa operação</h2>


                </div>
                <div class="col-12 col-lg-6 mt-3 mt-md-0">
                    <mat-form-field appearance="outline" class="w-100 text-white">
                        <mat-label>Quantidade de cota</mat-label>
                        <input matInput min="1" type="number" 
                        [value]="inputValue"
                        (input)="onInputChange($event)"
                        (blur)="onInputChange($event)"
                        />
                        <!--  [value]="inputValue"
                        (blur)="onInputChange($event)"

                        [ngModelOptions]="{standalone: true}" -->
                    </mat-form-field>
                    <!-- (change)="changeNumber($event)"  -->

                    <div *ngIf="result">
                        <h6>Ao final da operação você terá:</h6>
                        <h5 class="text-main fs-2" translate="no">{{ result | currency:'BRL' }}</h5>
                    </div>

                </div>
            </div>

            <div class="mt-3">
                <small translate="no">*Simulação projetada em rentabilidade de {{ tokenDetalhe?.VlPorcentagemRentabilidadeCDIBase |
                    number:'1.1-2':'pt' }}% considerando a análise de especialistas da MV
                    Ativos. Não há garantia que a estimativa se concretize.</small>
            </div>

        </div>
    </section>

    <section *ngIf="documents" class="py-5" id="documentos">
        <div class="container">
            <h2 class="text-main mb-5">Documentos</h2>

            <div *ngIf="allDocumentsWithoutUrl">
                <p>Todos os documentos estão indisponíveis.</p>
            </div>

            <div class="row" *ngIf="!allDocumentsWithoutUrl">
                <div *ngFor="let detail of documents" class="col-lg-6 col-sm-12 mb-4">
                    <div *ngIf="detail.url" class="card h-100">
                        <div
                            class="card-body d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center">
                            <h5 class="card-title document-title font-weight-normal mb-3 mb-sm-0" translate="no">{{ detail.nome }}</h5>

                            <button mat-stroked-button class="mat-button-base mat-primary" color="main"
                                (click)="openFile(detail.url)">
                                <mat-icon>open_in_new</mat-icon>
                                Acessar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <section class="py-5">
        <div class="container">
            <h2 class="text-main mb-5">Por que gostamos dessa oportunidade?</h2>

            <div class="row">
                <div *ngFor="let section of opportunities.mains" class="col-12 col-md-6 col-lg-6 mb-4">
                    <div class="card p-4 p-lg-5 h-100">
                        <mat-icon *ngIf="section.icon" class="text-alternative-dark mb-1 fs-1"
                            style="height: 40px; width: 60px;" translate="no">{{
                            section.icon }}</mat-icon>
                        <h4 class="card-title text-main mb-4" translate="no">{{ section.title }}</h4>
                        <div class="card-body">
                            <p translate="no">{{ section.description }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div *ngFor="let detail of opportunities.details" class="col-12 col-md-6 col-lg-4 mb-4">
                    <div class="card p-4 p-lg-5 h-100">
                        <mat-icon *ngIf="detail.icon" class="text-alternative-dark mb-1 fs-1"
                            style="height: 40px; width: 60px;" translate="no">{{
                            detail.icon }}</mat-icon>
                        <h4 class="card-title text-main mb-4" translate="no">{{ detail.title }}</h4>
                        <div class="card-body">
                            <div [innerHTML]="detail.description"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="py-5">
        <div class="container">
            <h2 class="text-main mb-5">Riscos</h2>

            <div class="row">
                <div *ngFor="let detail of risks.details; let last = last"
                    [ngClass]="{'col-12': last, 'col-md-6 col-lg-4': !last}" class="col-12 mb-4">
                    <div class="card p-4 p-lg-5 h-100">
                        <mat-icon *ngIf="detail.icon" class="text-alternative-dark mb-1 fs-1"
                            style="height: 40px; width: 60px;" translate="no">{{
                            detail.icon }}</mat-icon>
                        <h4 class="card-title text-main mb-4" translate="no">{{ detail.title }}</h4>
                        <div class="card-body">
                            <div [innerHTML]="detail.description"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="bg-main text-white py-5" id="originadora">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-9">
                    <h2 class="mb-5">Sobre a originadora</h2>
                    <div>
                        <p>A Paulista Distress é uma empresa de investimentos em ativos judiciais que oferece soluções
                            nesta
                            classe de ativos para investidores e
                            instituições financeiras que buscam diversificar suas carteiras de investimentos. Com uma
                            equipe
                            altamente especializada e experiente, a Paulista Distress cuida de todo o ciclo de vida dos
                            ativos judiciais, desde a originação, passando pela estruturação e gestão dos ativos.</p>
                        <p>Com ampla experiência no mercado de investimentos em ativos judiciais, a Paulista Distress se
                            destaca pela qualidade de seus serviços e pela capacidade de oferecer soluções que agregam
                            valor
                            aos investimentos de nossos clientes. Nosso foco é sempre maximizar o retorno financeiro dos
                            ativos judiciais, oferecendo segurança e eficiência em todas as etapas do processo.</p>
                    </div>
                </div>
                <div class="col-12 col-lg-3">
                </div>
            </div>
        </div>
    </section>

    <section id="section-pagamento" class="py-5">
        <div class="container">
            <h2 class="text-main">Pagamento</h2>
            <p>Confirme sua compra</p>

            <div class="row" id="card-investment-token">
                <div class="col-md-7">
                    <card-investment-token *ngIf="tokenDetalhe" [id]="tokenId" [tokenDetalhes]="tokenDetalhe"
                        [quantidadeSelecionada]="quantidade" (valueChanged$)="atualizarPrecos($event)">
                    </card-investment-token>
                </div>
                <div class="col-md-5">
                    <div class="mt-3 mt-md-0">
                        <div class="card">
                            <h3 class="text-main">Total a pagar</h3>
                            <h4><span translate="no">{{ precoTokenCarrinho | currency }}</span></h4>
                        </div>
                        <form autocomplete="off">
                            <button type="button" class="mt-4" mat-raised-button color="primary"
                                ng-disabled="!(paymentForm.valid && contaSelecionada !== null)"
                                (click)="realizarPedido()">
                                Ir para pagamento
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div *ngIf="showButton" class="position-fixed bottom-0 end-0 me-5 btn-float">
        <button type="button" mat-raised-button color="primary" (click)="scrollToSection('section-pagamento')">
            Ir para pagamento
        </button>
    </div>